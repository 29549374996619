import React from 'react';
import { FormRenderData, Module } from '@cuatroochenta/co-admin-react-library';
import CustomAction from './CustomAction';
import iconMicrosoft from "./res/icons/microsoft.png";

import '@cuatroochenta/co-admin-react-library/lib/index.css';

interface Filters {
    [dynamic: string]: string
}

class App extends React.Component {

    private beforeFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    private afterFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    private customButtonsList = (entity: string, filters: Filters, orderby: string, order: string): React.ReactNode => {
        return null;
    };

    private afterLoginButton = (): React.ReactNode => {
        return (
            <>
                {this.renderMicrosoftButton('sso/login.php', 'CUATROOCHENTA')}
                {this.renderMicrosoftButton('sso-sofistic/login.php', 'SOFISTIC')}
            </>
        );
    };

    private renderMicrosoftButton = (route: string, text: string): React.ReactNode => {
        return (
            <div className="mt-2">
                <a href={process.env.REACT_APP_URL + route} className="btn btn-lg btn-custom d-block" style={{backgroundColor: '#51cbce'}}>
                    <img className="mr-1" src={iconMicrosoft}/>
                    {text}
                </a>
            </div>
        );
    };

    public render() {
        return (
            <Module
                actions={[
                    { name: 'custom', element: <CustomAction/> }
                ]}
                beforeFormView={this.beforeFormView}
                afterFormView={this.afterFormView}
                customButtonsList={this.customButtonsList}
                afterLoginButton={this.afterLoginButton}
            />
        );
    }

}

export default App;
